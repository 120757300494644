import {
  memo,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';
// components
import Icon from 'src/components/Icon';
// context
import CursorContext from 'src/contexts/CursorContext';

import { Props } from './Dropdown.interface';
import * as style from './Dropdown.module.scss';

const Dropdown = forwardRef<HTMLInputElement, Props>(
  (
    { className, placeholder, error, handleChange, value, options, ...props },
    ref
  ) => {
    const { cursor } = useContext(CursorContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropDownRef = useRef<HTMLLabelElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
      cursor?.enter();
    };

    const handleMouseLeave = () => {
      cursor?.leave();
    };

    const handleSelect = (selectedValue: string) => () => {
      handleChange(selectedValue);
    };

    const handleClickInside = useCallback(() => {
      setIsOpen((prevState) => !prevState);
    }, []);

    const handleClickOutside = useCallback((e) => {
      if (
        dropDownRef.current !== null &&
        !dropDownRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    }, []);

    useEffect(() => {
      window.addEventListener('click', handleClickOutside);

      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, [handleClickOutside]);

    return (
      <label
        ref={dropDownRef}
        className={classNames(
          style.root,
          { [style.error]: !!error },
          className
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={style.inputField} onClick={handleClickInside}>
          {value ?? placeholder}
        </div>
        <input
          placeholder={placeholder}
          className={style.hidden}
          ref={ref}
          {...props}
        />
        <div className={classNames(style.icon, { [style.iconOpen]: isOpen })}>
          <Icon name='arrowLeft' />
        </div>
        <div
          ref={optionsRef}
          onClick={handleClickInside}
          className={classNames(style.list, { [style.open]: isOpen })}
        >
          {options.map((item, index) => (
            <div
              key={index}
              onClick={handleSelect(item.key)}
              className={style.option}
            >
              {item.value}
            </div>
          ))}
        </div>
        <div
          className={classNames(style.errorDescription, {
            [style.visible]: !!error && isOpen,
          })}
        >
          {error}
        </div>
      </label>
    );
  }
);

export default memo(Dropdown);
