import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { either } from 'fp-ts';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
// components
import Section from 'src/components/Section/Section';
import Contacts from 'src/components/Contacts';
import Typography from 'src/components/Typography/Typography';
import TextField from 'src/components/TextField/TextField';
import Button from 'src/components/Button/Button';
import Dropdown from 'src/components/Dropdown';
import PhoneNumberField from 'src/components/PhoneNumberField';
// containers
import MessageButton from 'src/containers/MessageButton';
// hooks
import useContactsData from 'src/cms/data/common/useContactsData';
import useJobsSectionData from 'src/cms/data/jobs/useJobsSectionData';
import { useObservable } from 'src/hooks/useObservalble';
// services
import {
  BecomeTeamMemberValues,
  sendBecomeTeamMemberData,
} from 'src/services/api';
// context
import AlertsContext from 'src/contexts/AlertsContext';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// store
import newJobStore from 'src/store/job/new-job.store';
// types
import { Option } from 'src/components/Dropdown/Dropdown.interface';
// styles
import * as style from 'src/containers/StartProject/StartProject.module.scss';

const phoneLength = 5;

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  position: yup.string(),
  coverLetter: yup.string(),
  email: yup.string().email('Email is not valid').required('Email is required'),
  phone: yup
    .string()
    .required('Phone is required')
    .min(phoneLength, 'Invalid phone length'),
});

const BecomeTeamMember: FC = () => {
  // hooks
  const { phone } = useContactsData();
  const { jobsList } = useJobsSectionData();
  const position = useObservable(newJobStore.position);
  const { register, handleSubmit, errors, setValue } = useForm<
    BecomeTeamMemberValues
  >({
    resolver: yupResolver(validationSchema),
  });
  // context
  const { addAlert } = useContext(AlertsContext);
  // state
  const [isDataSent, setIsDataSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  // refs
  const rootRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // memo
  const fileNames = useMemo(() => {
    const names: string[] = [];

    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        names.push(files[i].name);
      }
    }

    if (names.length === 0) {
      return 'Attach files';
    }

    return names.join(', ');
  }, [files]);

  const onSubmit = useCallback(
    async (data: BecomeTeamMemberValues) => {
      setIsLoading(true);

      const result = await sendBecomeTeamMemberData(
        {
          ...data,
          phone: `+${data.phone.replace(/\D/g, '')}`,
        },
        files
      );

      setIsLoading(false);

      either.fold<string, string, void>(
        (errorDescription) => {
          addAlert(errorDescription);
        },
        () => {
          setIsDataSent(true);
        }
      )(result);
    },
    [files]
  );

  const handleFileSelectClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles !== null) {
      setFiles(selectedFiles);

      return;
    }

    setFiles(null);
  }, []);

  const handleMessageButtonClick = useCallback(() => {
    if (rootRef.current !== null) {
      const rect = rootRef.current.getBoundingClientRect();

      window.scrollTo({
        top: rect.top + window.scrollY,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleDropdownChange = useCallback((value: string) => {
    newJobStore.setPosition(value);
  }, []);

  useEffect(() => {
    setValue('position', position);
  }, [position]);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  const positionOptions = useMemo<Option[]>(() => {
    return jobsList
      .filter((item) => item.isVisible)
      .map((item) => ({
        key: item.team,
        value: item.team,
      }));
  }, [jobsList]);

  return (
    <>
      <Section
        className={style.root}
        ref={rootRef}
        variant='pad-left'
        withMargin={false}
      >
        <Contacts />
        <div className={style.startProject}>
          {isDataSent ? (
            <>
              <Typography variant='h2'>
                We will get back to you shortly!
              </Typography>
              {phone && (
                <Typography className={style.successDescription} variant='body'>
                  For immediate help feel free to call us at
                  <a href={`tel: ${phone}`} className={style.phone}>
                    {` ${phone}`}
                  </a>
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography className={style.title} variant='h2'>
                BECOME PART OF THE TEAM
              </Typography>
              <form
                className={style.form}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <TextField
                  className={style.input}
                  placeholder='Name'
                  name='name'
                  ref={register({ required: true })}
                  error={errors.name?.message}
                />
                <Dropdown
                  className={style.input}
                  placeholder='Position'
                  name='position'
                  handleChange={handleDropdownChange}
                  value={position}
                  ref={register}
                  options={positionOptions}
                  error={errors.position?.message}
                />
                <TextField
                  className={style.input}
                  placeholder='Email'
                  name='email'
                  type='email'
                  ref={register}
                  error={errors.email?.message}
                />
                <PhoneNumberField
                  className={style.input}
                  placeholder='Phone'
                  name='phone'
                  type='tel'
                  ref={register}
                  error={errors.phone?.message}
                />
                <TextField
                  Element='textarea'
                  placeholder='Cover Letter'
                  name='coverLetter'
                  ref={register}
                  error={errors.coverLetter?.message}
                />
                <div className={style.actions}>
                  <Button
                    className={style.action}
                    text={fileNames}
                    onClick={handleFileSelectClick}
                    variant='file'
                    iconLeftName='attach'
                  />
                  <input
                    multiple
                    className={style.hiddenFileSelect}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    type='file'
                  />
                  <Button
                    className={style.action}
                    variant='secondary'
                    type='submit'
                    text='Submit'
                    isLoading={isLoading}
                    iconRightName='arrowRight'
                  />
                </div>
              </form>
            </>
          )}
        </div>
      </Section>
      <MessageButton onClick={handleMessageButtonClick} />
    </>
  );
};

export default BecomeTeamMember;
