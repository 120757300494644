import { BehaviorSubject } from 'rxjs';

class NewJobStore {
  readonly position = new BehaviorSubject<string | undefined>(undefined);

  setPosition = (value: string) => {
    this.position.next(value);
  };
}

export default new NewJobStore();
