// extracted by mini-css-extract-plugin
export var accordion = "JobsList-module--accordion--0f1ba";
export var apply = "JobsList-module--apply--648dc";
export var body = "JobsList-module--body--263f4";
export var content = "JobsList-module--content--0818c";
export var date = "JobsList-module--date--775a5";
export var iconReverse = "JobsList-module--iconReverse--b7a4c";
export var iconText = "JobsList-module--iconText--8fd1b";
export var iconWrapper = "JobsList-module--iconWrapper--2a343";
export var info = "JobsList-module--info--da640";
export var infoDescription = "JobsList-module--infoDescription--53691";
export var infoTitle = "JobsList-module--infoTitle--08c7d";
export var job = "JobsList-module--job--7fdcd";
export var link = "JobsList-module--link--be3a0";
export var linkWrapper = "JobsList-module--linkWrapper--59277";
export var root = "JobsList-module--root--08f04";
export var title = "JobsList-module--title--a63a2";