import { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Accordion from 'src/components/Accordion';
import Icon from 'src/components/Icon';
import Button from 'src/components/Button';
// hooks
import useJobsSectionData from 'src/cms/data/jobs/useJobsSectionData';
// animations
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';
// store
import newJobStore from 'src/store/job/new-job.store';

import * as style from './JobsList.module.scss';

const JobsList: FC = () => {
  // hooks
  const { jobsList } = useJobsSectionData();

  useEffect(() => {
    const rows = document.querySelectorAll<HTMLDivElement>(`.${style.title}`);

    rows.forEach((row) => {
      const animation = showFromOpacity(row, { duration: 2 }).pause();

      onHTMLElementEnterOnWindow(row, () => {
        animation.play();
      });
    });
  }, []);

  const handleClick = useCallback(
    (value: string) => () => {
      window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight });
      newJobStore.setPosition(value);
    },
    []
  );

  return (
    <Section className={style.root} withMargin={false}>
      <Accordion
        className={style.accordion}
        Title={(props) => (
          <div className={style.title}>
            {props.text}
            <div className={style.linkWrapper}>
              <Typography className={style.iconText} variant='body'>
                {props.activeRow ? 'Less' : 'More'}
              </Typography>
              <div
                className={classNames(style.iconWrapper, {
                  [style.iconReverse]: props.activeRow,
                })}
              >
                <Icon name='arrowLeft' />
              </div>
            </div>
          </div>
        )}
        Body={(props) => <div className={style.body}>{props.text}</div>}
        list={jobsList
          .filter((job) => job.isVisible)
          .map((job) => ({
            title: (
              <>
                <div className={style.job}>
                  <Typography className={style.date} variant='body'>
                    {job.date}
                  </Typography>
                  <Typography className={style.content} variant='body'>
                    {job.title}
                  </Typography>
                </div>
              </>
            ),
            body: (
              <>
                {job.info?.map((info, index) => (
                  <div className={style.info} key={index}>
                    <Typography className={style.infoTitle} variant='body'>
                      {info.title}
                    </Typography>
                    {info.description?.map((desc, descIndex) => (
                      <div className={style.infoDescription} key={descIndex}>
                        <Typography variant='body'>{desc}</Typography>
                      </div>
                    ))}
                  </div>
                ))}
                <Button
                  onClick={handleClick(job.team)}
                  className={style.apply}
                  variant='secondary'
                  text='Apply for this job'
                  iconRightName='arrowRight'
                />
              </>
            ),
          }))}
      />
    </Section>
  );
};

export default JobsList;
