import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useJobsIntroData from 'src/cms/data/jobs/useJobsIntroData';
import useJobsMetaData from 'src/cms/data/jobs/useJobsMetaData';
// sections
import JobsList from './sections/JobsList';
import BecomeTeamMember from 'src/pages/career/sections/BecomeTeamMember';
import { PageProps } from 'gatsby';

const Jobs: FC<PageProps> = ({ location }) => {
  // hooks
  const { title, mobileTitle, description } = useJobsIntroData();
  const jobsMetaData = useJobsMetaData();

  return (
    <PageLayout
      withStartProjectForm={false}
      metaData={{ ...jobsMetaData, href: location.href }}
    >
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='supermanDrawing'
        withMargin={false}
      />
      <JobsList />
      <BecomeTeamMember />
    </PageLayout>
  );
};

export default Jobs;
